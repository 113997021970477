.shadow{
    box-shadow: 0 0 20px 0 rgba(28, 28, 28, 0.18);
}

hr.dotted{
    border-top: 2px dotted #bbb;
}

label.active {
    color: #1c1c1c !important;
    padding-left: .75rem;
}

i.icon-grey{
    color: gray;
    font-size: 1.4rem;
    transition-duration: 0.4s;
}

.btnBack{
    width: 2.8rem;
    height: 2.8rem;
    background-color: transparent;
    border: 1px #676767 solid;
    border-radius: 50%;
    transition-duration: 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
}

.btnBack:focus {
    border: 1px #676767 solid;
    background-color: transparent !important;
}

.total{
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    color:#2a7bb1;
    font-weight: 600;
}

ul.dropdown-content.select-dropdown li span {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #676767;
    font-size: 1.4rem;
}

ul.dropdown-content.select-dropdown li span:hover{
    color: #00a1fd;
    background-color: rgba(0, 170, 235, 0.1);
}

.border-input-dropdown .select-wrapper .select-dropdown.dropdown-trigger{
    border: 1.5px #00a1fd solid !important;
    box-shadow: none!important;
}

.border-input-dropdown-error .select-wrapper .select-dropdown.dropdown-trigger{
    border: 1.5px #c70909 solid !important;
    box-shadow: none!important;
}

.buttonPay{
    font-family: 'Nexa';
    width: 100%;
    height: 4.5rem;
    border: 1px solid #00a1fd ;
    border-radius: 17px;
    background-color: #00a1fd;
    transition-duration: 0.4s;
    font-size: 1.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #f3f3f3;
}

.buttonPay:disabled {
    opacity: 0.5;
    cursor: default;
}

.buttonPay2 {
    font-family: 'Nexa';
    width: 80%;
    height: 2.5rem;
    border: 1px solid #00a1fd;
    border-radius: 17px;
    background-color: #00a1fd;
    transition-duration: 0.4s;
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #f3f3f3;
}

.buttonPay2:hover {
    cursor: pointer;
}

.tooltip {
    position: absolute;
    top: -.15rem;
    right: .75rem;
    visibility: hidden;
}

.italic {
    font-style: italic !important;
    width: 15rem;
}

.react-select__placeholder, .react-select__option {
    font-size: 20px !important;
}

.react-select__single-value, .react-select__menu-notice {
    font-size: 19.4555px !important;
}

.react-select__control  {
    height: 3.4rem !important;
}

.react-select__menu-list {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.info-icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: #635bff;
}

.hide-tooltip-mobile{
    opacity: 0 !important;
}

.buttonTryAgain {
    min-width: 20% !important;
    width: auto;
}

[type="checkbox"]:checked+span:not(.lever):before {
    border-right: 2px solid #00a1fd !important;
    border-bottom: 2px solid #00a1fd !important;
    border-top: 2px solid transparent !important;
    border-left: 2px solid transparent !important;
}

[type="checkbox"]+span:not(.lever):before, [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
    border: 2px solid #676767 !important;
}

[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after {
    border: 2px solid #00a1fd !important;
}

[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after {
    background-color: #00a1fd !important;
}

/*TODO LO QUE NO SEA TELEFONO*/
@media(min-width:601px){

    /*PREGUNTARLE A TOCHON*/
    .btnBack:hover{
        cursor: pointer;
    }
    
    .btnBack:hover i.icon-grey{
        color: white;
    }

    .btnBack{
        border: none;
        left: 3.5rem;
    }

    .btnBack:focus {
        border: none;
        background-color: transparent !important;
    }

    .btnBack img {
        padding: 0 .4rem;
    }

    .volver {
        color: rgb(103, 103, 103);
        font-weight: bold;
        font-family: Nexa;
        font-size: 1.2rem;
        margin-top: 3px;
    }

    /*No lo soporta IE9*/
    .buttonPay:hover:not([disabled]) {
        background-color: #f3f3f3 !important;
        cursor: pointer;
        color: #00a1fd;
        border: 1px solid #00a1fd ;
    }

    .tooltip {
        visibility: visible;
    }

    .hide-tooltip-mobile{
        opacity: 0.9 !important;
    }

    .react-select__placeholder, .react-select__single-value, .react-select__option, .react-select__menu-notice {
        font-size: 16px !important;
    }
}

/* 4K */
@media(min-width: 2560px){
    .buttonPay {
        height: 5.5rem;
        font-size: 2.2rem;
    }

    .buttonPay2 {
        height: 3.5rem;
        font-size: 1.4rem;
    }

    .react-select__placeholder, .react-select__single-value, .react-select__option, .react-select__menu-notice {
        font-size: 26px !important;
    }

    .react-select__option{
        height: 71px !important;
    }

    .react-select__control  {
        height: 4.1rem !important;
    }
}