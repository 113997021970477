@font-face {
  font-family: 'Nexa';
  src: local('NexaRegular'), local('Nexa-Regular'), url('./components/fonts/NexaBook.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

html{
  height: 100%;
}

.version {
  position: absolute;
  top: 0;
  left: 0;
  color: #676767;
  font-style: italic;
  padding: .5rem;
}

/*Que se vea todo un poco más grande a partir de 1200px*/
@media only screen and (min-width: 2560px){
  html {
    font-size: 16px !important;
  }
}

body {
  height: 100%;
  margin: 0;
}

#root {
  min-height: 100%;
}

#root.set-background {
  min-height: 100%;
  background: url('./components/styles/bckg-mobile.svg') no-repeat bottom;
  background-size: contain;
}

/*Styles for when the user enter on instagram*/
.site-container-instagram {
  width: 100%;
}

.front-card-instagram {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

/*Input de tipo numero sin las flechas por default*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*Quita lo azul del autocompletado en los inputs*/
/*Para los autocompletes*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
/*Para los autocompletes*/

input:not([type]):disabled, input[type=text]:not(.browser-default):disabled {
  color: #4d4d4d;
  padding: 0 !important;
  width: 100% !important;
  height: 3.2rem !important;
  border-radius: 6px !important;
}

.row .col{
  min-height: 19px !important;
}

@media (max-width: 600px) {
  .row .col.s9{
    margin-left: 11.3333333333% !important;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nexa';
  color: rgb(0, 0, 0);
}

button {
  line-height: 2.2 !important;
}

button:focus{
  outline: none !important;
  background-color: #00a1fd !important;
}

.container {
  padding: 1rem 0 1rem 0;
}

form{
  padding: 2rem 0rem .5rem !important;
}

span.invalid{
  color:#c70909 !important;
  font-size: 13px !important;
}

.loading{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto !important;
}

.absolute-loading {
  position: absolute !important;
}

#preloader-overlay {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255);
  z-index: 20000;
}

#preloader {
  position: fixed;
  z-index: 20001;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.payment,.payment-body{
  margin: 2.5rem 4rem 1rem;
}

.payment .description{
  font-size: 1.1rem;
  text-align: justify;
  overflow-wrap: break-word;
}

.ubiBlue2{
  color:#2a7bb1 !important;
}

.ubiRed{
  color: #990033;
}

.ubiGreen{
  color: #29a329;
}

.ubiiYellow {
  color: #FFBC00;
}

h5{
  font-size: 1.3rem !important;
  margin-top:.4rem !important;
}

h4 {
  margin: 0 !important;
}

.select-dropdown li img {
  width: 80px !important;
  pointer-events: none;
}

.ubiiCard {
  text-align: center;
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
  color: #676767;
  border-radius: 12px;
  font-size: 1.4rem;
  margin: 0.7rem auto 2rem;
  padding: 0.5rem 2rem;
  
}

.ubiiCard p {
  margin: 0;
}

.ubiiCard .mb {
  margin-bottom: 15px;
}

.ubiiCard .b {
  font-weight: bold;
  color: #4d4d4d;
}

.pad {
  padding-bottom: 1rem;
}

.billItem {
  color: #8f8f8f;
  font-size: 1rem;
}

.billItem2 {
  color: #8f8f8f;
  font-size: 1.2rem;
  padding-top: .5rem !important;
}

.billItem2 span b {
  color: #1c1c1c;
  font-size: 1.2rem;
}

.comp {
  opacity: 0.65;
  font-size: 1.1rem !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.ubiiBlue {
  color: #026bf1; 
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.frontCard {
  position: relative;
  z-index: 2;
  background-color: #fff;
  margin: 5% auto 1rem auto;
  border-radius: 17px;
}

.dropdown-content {
  box-shadow: 0 3px 10px 0 rgba(28, 28, 28, 0.18);
  border: solid 1px #00a1fd;
  border-radius: 7px;
  background-color: #ffffff;
}

.noMargin {
  margin: 0;
}

.text-center {
  text-align: center !important;
  padding: 0 !important;
  width: 100% !important;
  height: 4.5rem !important;
  border-radius: 12px !important;
  font-size: 20px !important;
}

.responsivePadding {
  margin: .5rem 0 0 !important;
}

.select-dropdown.dropdown-content li.selected{
  background-color: unset !important;
}

.select-dropdown.dropdown-content li.selected:hover{
  background-color: unset !important;
}

.select-dropdown.dropdown-content li:hover{
  background-color: unset !important;
}

.autocomplete-content li>span {
  color: #2a7bb1 !important;
  /* background-color: rgba(0, 170, 235, 0.1); */
}

.autocomplete-content li>span:hover {
  color: #2a7bb1 !important;
  background-color: rgba(0, 170, 235, 0.1);
}

/*TOCHON*/
.tabs {
  overflow-x: hidden !important;
  display: flex !important;
}

.tabs .tab a.active {
  background-color: #00a1fd !important;
  color: #f3f3f3 !important;
  border: #00a1fd 1px solid;
  border-radius: 12px;
  position: absolute;
  width: 50% !important;
}

.tabs .tab a.left-tab:hover, .tabs .tab a.left-tab.active {
  box-shadow: 8px 0px 8px 0px rgb(28 28 28 / 18%);
}

.tabs .tab a.right-tab:hover, .tabs .tab a.right-tab.active {
  box-shadow: -8px 0px 8px 0px rgb(28 28 28 / 18%);
}

.tabs .indicator{
  display: none;
}

.tabs .tab a {
  background: #f3f3f3 !important;
  color: #8f8f8f !important;
  border-radius: 12px;
  border: 1px solid #f3f3f3;
  transition: border .1s ease-in, color .2s ease-in-out, background-color .2s ease-in-out !important;
  height: 87.8% !important;
  font-size: 16px !important;
}

.tabs .tab {
  line-height: 46.9px !important;
  flex-grow: 1;
  text-transform: none !important;
}

.input-field>label:not(.label-icon).active {
  transform: translateY(-34px) scale(1.6) !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

input {
  font-family: 'Nexa' !important;
  background-color: #f3f3f3 !important;
  border: 1.5px #f3f3f3 solid !important;
  border-radius: 6px !important;
  height: 3.2rem !important;
  width: 97% !important;
  font-size: 19.4555px !important;
  text-align: left;
  color: #676767;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  padding: 0 0 0 3% !important;
  background-image:-webkit-gradient(linear, 0% 0%, 0% 100%, from(hsla(0,0%,100%,0)), to(hsla(0,0%,100%,0)));
  background-image:-webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0));
}

input:focus {
  box-shadow: none!important;
}

.mock-input {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nexa' !important;
  background-color: #f3f3f3;
  border: 1.5px #f3f3f3 solid;
  color: #676767;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  padding: 0;
  width: 100%;
  height: 4.5rem;
  border-radius: 12px;
  font-size: 20px;
}

.in-form {
  color: #4d4d4d !important;
  height: 3.2rem !important;
  border-radius: 6px !important;
  margin-bottom: 1.5rem !important;
}

.input-form:focus {
  border: 1.5px #00a1fd solid !important;
  box-shadow: none!important;
}

.border-input {
  border: 1.5px #00a1fd solid !important;
  box-shadow: none!important;
}

.red-border {
  border: 1.5px #c70909 solid !important;
  box-shadow: none!important;
}

.input-field {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.input-field.col label {
  left: 0 !important;
}

.select-wrapper input.select-dropdown {
  background-color: rgba(243, 243, 243, 0.6) !important;
}

.titles {
  font-size: 1rem;
  transform: scale(1.6);
  transform-origin: 0 0;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1c1c1c;
}

.titles-2 {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #1c1c1c;
}

.titles-2 > h5 {
  font-size: 1.8rem !important;
  font-weight: bold !important;
}

.titles-3 {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #676767
}

.titles-3 > h5 {
  font-size: 1.5rem !important;
}

.titles-3 > h5 > span {
  font-weight: bold !important;
}

.size-2 {
  font-size: 1.2rem;
}

.no-margin{
  margin-top: 0 !important;
}

.carousel .carousel-item {
  opacity: 1 !important;
  height: 7.5rem !important;
  border: rgba(28, 28, 28, 0.18) 2.3px solid;
  border-radius: 12px;
  transition: border .1s ease-in;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8f8f8f;
  font-size: 16px;
}

.carousel .active-border{
  border: #00a1fd 2.5px solid;
  color: #1c1c1c;
}

.listed-items-wrapper .active-border{
  box-shadow: 0 0 0 2px #00a1fd;
  color: #1c1c1c;
}

.carousel {
  display: block;
  height: 120px !important;
}

.pb2 {
  padding-bottom: 1rem !important;
}

.label-payments {
  color: #8f8f8f !important;
  position: relative !important;
  font-size: 1.4rem !important;
}

.label-payments.active {
  color: #8f8f8f !important;
  font-weight: normal !important;
  padding: 0.3rem 0;
}

.margin-top-08 {
  margin-top: 1.8rem !important;
}

input:disabled {
  margin-bottom: 1.5rem !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.margin-bottom-2 {
  margin-bottom: 1rem !important;
}

.bill-card {
  margin-top: 0 !important;
  border: 1px rgba(28, 28, 28, 0.18) solid;
  padding: 1rem 0.75rem !important;
  border-radius: 7px;
}

.move-right {
  text-align: right;
}

.move-left {
  text-align: left;
}

#ubiipagos {
  color: rgba(28, 28, 28, 0.24);
  font-size: 1.6rem !important;
}

.ubiiGrey {
  color: #8f8f8f;
}

.listed-items-wrapper {
  display:none;
  margin-bottom: 0 !important;
}

.listed-item {
  box-shadow: 0 0 0 1px rgba(28, 28, 28, 0.18);
  border-radius: 12px;
  transition: box-shadow .1s ease-in;
  margin-bottom: .96rem;
  height: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8f8f8f;
  transition: background-color .1s ease-in, color .1s ease-in;
}

.listed-item:hover{
  cursor: pointer;
}

.card-wrapper:hover {
  cursor: pointer;
}

.method-card {
  height: 3rem;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#country-drop input, #bank-drop input {
  height: 2rem !important;
  margin: 0 !important;
}

.size-tdd-info {
  font-size: 1.4rem !important;
}

.label-dni {
  text-align: center;
}

.padding-bottom-tabs {
  padding-bottom: 4rem !important;
}

.select-wrapper .caret {
  fill: rgba(0,0,0,1) !important;
}

.centerTab:hover{
  cursor: auto;
}

.bad-amount {
  font-size: 1.3rem !important;
  color: #c70909;
}

.title-helper {
  color: #676767;
  font-size: 1.3rem;
}

.desliza {
  font-size: 1rem;
  color: #676767;
  text-align: left;
}

.div-wrapper-input {
  position: relative;
}

.clipboard {
  position: absolute;
  visibility: visible;
  top: 1rem;
  right: 0;
  color: rgba(77, 77, 77, .9);
  font-size: 1.5rem;
  transition: color .2s ease;
}

.copied {
  animation: change 2s ease;
}

.title-commerce{
  color: #1c1c1c !important;
  font-weight: bold;
  font-stretch: normal;
  margin-top: 1rem !important;
  font-style: normal;
  font-size: 1.6rem;
  word-wrap: break-word;
}

.errorImage{
  height: 18rem;
  padding: 2rem;
}

@keyframes change {
  0%   { color:  rgb(99, 91, 255)}
  100% {color: #4d4d4d}
}

/* TELEFONO */
@media(max-width:600px){

  .payment,.payment-body{
    margin: 2.5rem 2rem 1rem;
  }

  .frontCard {
    margin: 18.7777% auto 8% auto;
  }

  .dropdown-content {
    transform: none !important;
  }

  .responsivePadding {
    padding: 0 !important;
    margin: 0 !important;
  }

  .label-payments.active {
    line-height: 1.2;
  }

  .title-commerce{
    font-size: 1.4rem;
  }

  .errorImage{
    height: auto;
    padding: 0;
  }
}

/* IPAD */
@media(max-width:992px)  and (min-height: 1024px) {
  .frontCard {
    margin: 25% auto 1rem auto;
  }
}

/*TODO LO QUE NO SEA TELEFONO*/
@media (min-width: 601px) {

  /* BACKGROUND CHANGE */
  #root.set-background {
    min-height: 100%;
    background-image: url('./components/styles/bckg-desktop-1.svg'), url('./components/styles/bckg-desktop-2.svg');
    background-size: 18rem, 20rem;
    background-position: left bottom, right top;
    background-repeat: no-repeat;
  }

  /* HOVERS QUE SOLO SIRVEN EN DESKTOP */
  .input-form:hover {
    border: 1.5px #00a1fd solid !important;
    box-shadow: none!important;
  }
  .listed-item:hover {
    background-color: rgba(0, 161, 253, 0.55);
    box-shadow: 0 0 0 1px rgba(28, 28, 28, 0.18);
    /* border: solid 2px rgba(28, 28, 28, 0.18); */
    
    color: #ffffff;
  }

  .listed-item:hover:not(#UBIIAPP,#C2P) {
    color: #ffffff;
  }

  .listed-item#UBIIAPP:hover, .listed-item#C2P:hover {
    color: #1c1c1c !important;
  }

  .listed-item#UBIIAPP:not(.active-border):hover .method-card, .listed-item#C2P:not(.active-border):hover .method-card {
    filter: sepia(1) brightness(100);
  }

  .active-border:hover {
    background-color: #ffffff !important;
    color: #1c1c1c !important;
    box-shadow: 0 0 0 2px #00a1fd !important;
  }

  .tabs .tab a:hover {
    border: #00a1fd 1px solid;
  }

  .images-bill:hover{
    cursor: pointer;
  }

  .clipboard:hover {
    cursor: pointer;
    color: rgb(99, 91, 255);
  }
  /* HOVERS QUE SOLO SIRVEN EN DESKTOP */

  /* MOSTRAR FLEX Y DESAPARECER CARRUSEL */
  .carousel{
    display: none;
  }

  .listed-items-wrapper{
    display: block;
  }
  /* MOSTRAR FLEX Y DESAPARECER CARRUSEL */

  /* ARREGLOS DE ESPACIO */

  .payment,.payment-body{
    margin: 2.5rem 3rem 1rem;
  }

  .input-field>label:not(.label-icon).active {
    transform: translateY(-30px) scale(1.3) !important;
  }

  .titles {
    transform: scale(1.3);
  }

  .titles-2 > h5 {
    font-size: 1.7rem !important;
    margin-top: 1rem !important;
  }

  .row .col.m11 {
    margin-left: 4.16666666666% !important;
  }

  .label-payments {
    font-size: 1.3rem !important;
  }

  .titles-3 > h5 {
    font-size: 1.3rem !important;
  }

  .fix-to-bottom{
    display: flex;
    align-items: flex-end;
  }

  .fix-to-bottom .input-field .helper-text {
    min-height: 48px;
  }

  input {
    font-size: 16px !important;
  }

  .label-dni {
    text-align: left;
  }

  .size-tdd-info {
    font-size: 1.2rem !important;
  }

  .method-card {
    height: 2.8rem;
  }
  /* ARREGLOS DE ESPACIO */
}

/* 4K */
@media(min-width: 2560px){
  .html {
    font-size: 16px;
  }
  
  .tabs {
    height: 68px !important;
  }

  .tabs .tab{
    line-height: 58.9px!important;
    height: 68px !important;
  }

  .tabs .tab a {
    font-size: 25px !important;
  }

  .input-field>label, .label-dni>label {
    font-size: 1.5rem !important;
  }

  .input-field>label:not(.label-icon).active{
    transform: translateY(-40px) scale(1.3)!important;
  }

  .text-center{
    height: 5.5rem!important;
    font-size: 35px!important;
  }

  form {
    padding: 3.5rem 0 .5rem!important;
  }

  .padding-bottom-tabs{
    padding-bottom: 6rem !important;
  }

  span.invalid {
    font-size: 23.222px!important;
  }

  .titles {
    font-size: 1.5rem;
  }

  .margin-bottom-2 {
    margin-bottom: 1.2rem!important;
  }

  .listed-item {
    height: 8rem;
    font-size: 23px;
    margin-bottom: 1rem;
  }

  .method-card {
    height: 4rem;
  }

  input {
    font-size: 26px !important;
    height: 3.9rem !important;
  }

  .mock-input {
    /* font-size: 26px !important; */
    height: 5.5rem!important;
    font-size: 35px!important;
  }

  .in-form{
    height: 3.9rem !important;
    font-size: 26px !important;
  }

  .titles-3 > h5 {
    font-size: 1.5rem !important;
  }

  .billItem, .billItem2 {
    font-size: 1.6rem;
  }

  .col.s12.center-align.margin-top-08 > h5 {
    font-size: 1.6rem !important;
  }

  .comp {
    font-size: 1.555rem !important;
  }

  .titles-2 > h5 {
    font-size: 2.3rem !important;
    margin-top: 1.6rem !important;
  }

  input:not([type]):disabled, input[type=text]:not(.browser-default):disabled{
    height: 3.9rem !important;
    font-size: 26px !important;
  }

  .ubiiCard {
    font-size: 1.7rem;
  }

  .size-tdd-info, .billItem2 span b {
    font-size: 1.6rem !important;
  }

  .images-bill {
    height: 3rem;
  }
  
  #ubiipagos {
    font-size: 2.2rem !important;
  }
}

.icon-share {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  line-height: 0 !important;
}

.icon-share:focus {
  background-color: transparent !important;
}

.cash-instructions-card {
  background-color: rgb(243, 243, 243);
  margin: 1rem 0 1.5rem 0;
  padding: 1.5rem !important;
  text-align: justify;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #676767;
}

.cash-instructions-card>h5>span {
  line-height: 30px;
  overflow-wrap: break-word;
}

.loading-button{
  opacity: 1 !important;
}

.fadingEffect {
  opacity: 0.5;
  position:absolute;
  top:0; bottom:0; right:0;
  width:0%;
  background:white;
  -moz-animation: showHide 5s linear; /* Firefox */
  -webkit-animation: showHide 5s linear; /* Safari and Chrome */
  -ms-animation: showHide 5s linear; /* IE10 */
  -o-animation: showHide 5s linear; /* Opera */
  animation: showHide 5s linear;
}
@-webkit-keyframes showHide { /* Chrome, Safari */
  0% {width:100%}
  100% {width:0%;}
}
@-moz-keyframes showHide { /* FF */
  0% {width:100%}
  100% {width:0%;}
}
@-ms-keyframes showHide { /* IE10 */
  0% {width:100%}
  100% {width:0%;}
}
@-o-keyframes showHide { /* Opera */
  0% {width:100%}
  100% {width:0%;}
}
@keyframes showHide {
  0% {width:100%}
  100% {width:0%;}
}

/* input[type=checkbox], input[type=radio] {
  width: auto !important;
} */

.inputs-align-left > p {
  text-align: left;
}