.resultAnim {
  width: 60%;
  margin: 5% auto 20%;
  position: relative;
  animation-delay: 1s;
  animation-name: up;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.title-movement {
  margin-top: 25%;
  animation-delay: 1s;
  animation-name: up2;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.circle{
  stroke-dasharray: 370;
  stroke-dashoffset: 340;
  animation-name: dash;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.circle-three-dashes {
  stroke-dasharray: 332, 5, 5, 5, 5, 5, 5, 5, 5;
  stroke-dashoffset: 340;
  animation-name: dash;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.appear-effect {
  opacity: 0;
  animation-delay: 1s;
  animation-name: appear;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.line{
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation-name: dash;
  animation-duration: 1s;
  animation-delay: .8s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.check{
  stroke-dasharray: 1000;
  stroke-dashoffset: -120;
  animation-name: dash-check;
  animation-duration: 1s;
  animation-delay: .8s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.clock{
  stroke-dasharray: 1000;
  stroke-dashoffset: -120;
  animation-name: dash-check;
  animation-duration: 3.5s;
  animation-delay: .8s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.clock-middle {
  opacity: 0;
  animation-name: appear;
  animation-delay: 1.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes dash {
  to{
    stroke-dashoffset: 40;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -110;
  }
  100% {
    stroke-dashoffset: 850;
  }
}

@keyframes up {
  from {margin: 5% auto 20%;}
  to {margin: 0;width: 30%;}
}

@keyframes up2 {
  from {margin-top: 25%;}
  to {margin-top: 0.8rem;}
}

@media (min-width: 601px) {

  .resultAnim {
    width: 10rem;
  }

  .title-movement {
    margin-top: 4rem;
  }

  @keyframes up {
    from {margin: 5% auto 20%;}
    to {margin: 0;width: 6rem;}
  }

  @keyframes up2 {
    from {margin-top: 4rem;}
    to {margin-top: 0.8rem;}
  }
}

@keyframes appear {
  to {
    opacity: 1;
  }
}